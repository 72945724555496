function goToRegister(id) {
  var params = ''

  params += '?register'
  // if (localStorage.getItem('country_name') == 'Canada') {
  //   params += '?plan=cad'
  // } else {
  //   params += '?plan=usd'
  // }
  if (!!localStorage.getItem('_lm_coupon')) {
    params += '&coupon=' + localStorage.getItem('_lm_coupon')
  }

  // Add the form value (we're not using this)
  // params += '&email=' + encodeURIComponent(document.getElementById(id).value)

  window.location.href = 'https://app.priffly.com' + params
}

window.goToRegister = goToRegister
